.error{
    padding-top: 115px;
    min-height: calc(100vh - 250px);
    min-width: 75%;
    text-align: center;
    color: var(--color-white);
}

.error .h2-background{
    margin-left: 50%;
    transform: skewX(20deg) translateX(-50%);
    margin-bottom: 25px;
}

.error h3{
    margin-bottom: 5px;
}