.about{
    padding-top: 115px;
    display: flex;
    flex-direction: column;
}

.about > h3{
    text-transform: uppercase;
    color: var(--color-white);
    margin: 0 auto;
    font-size: 42px;
    margin-bottom: 50px;
}

.background-about-me{
    transform: skewY(2deg);
    background-image: url(../img/background.jpg);
    background-attachment: fixed;
    background-size: cover;
    background-repeat: no-repeat;
}

.about-me{
    transform: skewY(-2deg);
    display: flex;
    flex-direction: row;
    max-width: 1400px;
    margin: 0 auto;
    padding-top: 50px;
}

.about-me img{
    width: 250px;
    height: 250px;
    border-radius: 50%;
    border: 7px solid #00539f;
    margin-right: 75px;
    margin-left: 25px;
}

.about-page-cards{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.about-page-cards > div{
    min-width: 250px;
    flex: 1 1 45%;
    margin-bottom: 25px;
    margin-right: 5%;
}

.about-page-cards p{
    color: #00539f;
    font-size: 22px;
}

.about-page-cards h3{
    color: var(--color-white);
    background-color: #00539f;
    width: fit-content;
    text-transform: capitalize;
    font-size: 16px;
    padding: 3px;
}

.about-information{
    margin-bottom: 100px;
}

.about-page-resume{
    color: #00539f;
    font-size: 18px;
}

.about-page-resume p{
    margin-bottom: 10px;
}

.about-formation, .about-experience{
    max-width: 1400px;
    margin: 50px auto 0 auto;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 10px;
}

.about-formation h2, .about-experience h2{
    padding: 3px;
}

.card-formation, .card-experience{
    margin-top: 25px;
    color: #000;
    font-size: 22px;
    margin-bottom: 50px;
    width: 100%;
    padding: 0 15px;
}

.card-formation h5, .card-experience h5{
    font-size: 16px;
    color: var(--color-white);
    margin-top: 10px;
}

.card-experience ul{
    color: var(--color-white);
    margin-top: 10px;
    font-size: 18px;
    text-align: left;
    list-style: none;
}

.card-experience ul li > * {
    margin-right: 15px;
}

.card-experience ul li{
    margin-bottom: 5px;
}

@media only screen and (max-width: 768px){
    .about-me{
        flex-direction: column;
        align-items: center;
    }

    .about-me img{
        margin-bottom: 75px;
    }

    .about-information{
        margin-right: 50px;
        margin-left: 50px;
    }
}