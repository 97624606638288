.cv {
    display: flex;
    flex-direction: row;
    font-family: Arial, Helvetica, sans-serif;
    --color-white: #fff;
    --color-light: #ffffffab;
    --color-light-grey: #6d6d6d;
    --color-light-blue: #a6c8e7;
    --color-blue: #0052A3;
}

/* CV Left - start */
.cv .left {
    min-width: 250px;
    width: 25%;
    background-color: var(--color-blue);
    min-height: 100vh;
    height: auto;
    display: flex;
    flex-direction: column;
    color: var(--color-white);
}

.cv .left img {
    min-width: 150px;
    width: 15vw;
    border-radius: 50%;
    margin: 50px 0;
    margin-left: 50%;
    transform: translateX(-50%);
}

.cv .left > *:not(img):not(.cv-contacts) {
    margin-left: 50px;
}

.cv .left h2 {
    font-size: 24px;
    border-bottom: 2px solid var(--color-white);
    padding-bottom: 15px;
    margin-bottom: 25px;
}

.cv .card-info {
    font-size: 14px;
    margin: 7px 0;
}

.cv .card-info h3 {
    font-weight: bold;
    margin-bottom: 2px;
}

.cv .card-info p,
.cv .card-info a {
    color: var(--color-light);
    text-decoration: initial;
}

.cv .cv-contacts {
    margin-top: 25px;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    text-align: center;
}

.cv .cv-contacts > * {
    color: var(--color-white);
    margin: 5px 0;
    cursor: pointer;
    font-size: 45px;
    flex: 50%;
}
/* CV left - end */

/* CV main -start */
.cv .main {
    width: 100%;
}

.cv .main .card h2 {
    font-size: 24px;
}

.cv .main .card h2 {
    color: var(--color-blue);
    margin: 25px 50px;
    font-weight: bold;
    padding-left: 5px;
    padding-bottom: 15px;
    border-bottom: 1px solid var(--color-light-blue);
}

.cv .main .card p,
.cv .main .card ul li,
.cv .main .card a {
    font-size: 14px;
    color: var(--color-light-grey);
}

.cv .main .card p,
.cv .main .card ul li {
    text-indent: 15px;
    margin-left: 35px;
}

.cv .main .card p {
    line-height: 20px;
    margin-right: 50px;
}

.cv .main .card .time-card {
    display: flex;
    flex-direction: row;
    margin: 15px 50px;
    align-items: flex-start;
    justify-content: space-between;
}

.cv .main .card .time-card .card-content {
    width: 80%;
}

.cv .main .card .time-card .card-content strong {
    margin-right: 5px;
    font-style: italic;
}

.cv .main .card .time-card h3 {
    font-size: 18px;
}

.cv .main .card .time-card h4 {
    color: #5EB7DF;
    font-size: 14px;
    font-weight: 100;
    font-style: italic;
    margin-bottom: 5px;
}

.cv .main .card .time-card div {
    display: flex;
    flex-direction: column;
    font-size: 12px;
}

.cv .main .card .time-card div .github {
    flex-direction: row;
    justify-content: space-around;
}

.cv .main .card .time-card div .github a {
    font-size: 18px;
}

.cv .main .card .skill-card {
    margin: 0 50px;
    display: flex;
    flex-direction: row;
    padding: 5px 0;
    font-weight: bold;
    align-items: center;
    justify-content: center;
}

.cv .main .card .skill-card > * {
    padding: 0 5px;
}

.cv .main .card .skill-card a {
    text-align: left;
    color: var(--color-light-grey);
    width: 150px;
}

.cv .main .card .skill-card progress {
    width: 200px;
    height: 15px;
    border-radius: 15px;
}
  
.cv .main .card .skill-card progress::-webkit-progress-value {
    border-radius: 15px;
    background-color: var(--color-blue);
}
  
.cv .main .card .skill-card progress::-webkit-progress-bar {
    border-radius: 15px;
    background-color: var(--color-light-blue);
}
/* CV main - end */

/* CV Download PDF - start */
.button-pdf {
    cursor: pointer;
    position: fixed;
    font-size: 32px;
    top: 50px;
    right: 50px;
    
    color: var(--color-blue);
    background-color: var(--color-white);
    border-radius: 50%;
    transition: all ease 0.4s;
}

.button-pdf:hover {
    transform: scale(1.5);
}

@media print {
    .cv .main .card .time-card div a {
        font-size: 8px;
    }

    .cv .main .card,
    .cv .main .card .time-card {
        page-break-inside: avoid;
        padding-top: 10px;
    }
    
    .cv .left {
        height: 100%;
    }

    .leftPDF {
        height: 100%;
        position: fixed;
        left: 0;
        width: 250px;
        background-color: var(--color-blue);
        z-index: -1;
    }
}
/* CV Download PDF - end */