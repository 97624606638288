.skill{
    padding-top: 115px;
    display: flex;
    flex-direction: column;
    min-height: calc(100vh - 250px);
}

.skill > h3{
    text-transform: uppercase;
    color: var(--color-white);
    margin: 0 auto;
    font-size: 42px;
    margin-bottom: 50px;
}

.background-skills{
    transform: skewY(2deg);
    background-color: var(--color-white);
}

.all-skills{
    transform: skewY(-2deg);
    display: flex;
    flex-direction: row;
    max-width: 1400px;
    flex-wrap: wrap;
    margin: 100px auto;
}

.all-skills > * {
    flex: 1 1 33%;
    min-width: 33%;
    max-width: 33%;
}

.skills-card{
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 5px 0;
}

.skills-card h4{
    width: 50%;
    text-align: right;
    padding-right: 20px;
    font-size: 22px;
}

.skills-card:not(:nth-child(3n)){
    border-right: 2px solid #000;
}

.skills-card > * {
    font-size: 22px;
}

@media only screen and (max-width: 1000px){
    .all-skills > * {
        flex: 1 1 50%;
        min-width: 50%;
        max-width: 50%;
    }

    .skills-card:not(:nth-child(3n)){
        border-right: none;
    }

    .skills-card:not(:nth-child(2n)){
        border-right: 2px solid #000;
    }
}

@media only screen and (max-width: 768px){
    .all-skills > * {
        flex: 1 1 100%;
        min-width: 100%;
        max-width: 100%;
    }

    .skills-card:not(:nth-child(2n)){
        border-right: none;
    }
}