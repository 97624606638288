.project{
    padding-top: 115px;
    display: flex;
    flex-direction: column;
}

.project > h3{
    text-transform: uppercase;
    color: var(--color-white);
    margin: 0 auto;
    font-size: 42px;
    margin-bottom: 50px;
}

.background-project{
    transform: skewY(2deg);
    background-image: url(../img/background.jpg);
    background-attachment: fixed;
    background-size: cover;
    background-repeat: no-repeat;
}

.project-card{
    transform: skewY(-2deg);
    padding-bottom: 200px;
}

.project-main{
    display: flex;
    flex-direction: row;
    min-height: 300px;
    padding: 100px 145px 0 105px;
}

.project-image{
    border-radius: 15px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    background-color: #e2e2e2;
}

.pc{
    max-width: 600px;
    padding: 0 25px;
}

.mobile{
    max-width: 300px;
    padding: 0 25px;
}

.project-info{
    background-color: #e2e2e2;
    width: 100%;
    min-width: 600px;
    padding-bottom: 25px;
    border-radius: 15px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
}

.project-title{
    text-align: center;
    padding: 0 50px;
}

.project-title h4{
    font-size: 32px;
    margin-top: 25px;
    color: #2B2926;
}

.project-title h5{
    font-size: 18px;
    color: var(--color-light-grey);
    margin-bottom: 25px;
}

.project-info ul{
    list-style: none;
    margin-left: 50px;
    padding-right: 50px;
    font-size: 16px;
}

.project-info ul li{
    margin-bottom: 5px;
}

.project-info ul li strong{
    color: var(--color-blue);
    font-style: italic;
    margin-right: 5px;
}

.project-details{
    display: flex;
    flex-direction: row;
    width: 50%;
    background-color: var(--color-blue);
    box-shadow: 2px 2px 2px var(--color-light-grey);
    font-size: 22px;
    transform: skewX(20deg);
    margin: -10px auto 0 auto;
    padding: 15px 0;
    align-items: center;
    color: var(--color-white);
}

.project-details > * {
    transform: skewX(-20deg);
}

.project-details h4{
    min-width: fit-content;
    margin-left: 25px;
}

.project-demo{
    width: 100%;
    text-align: right;
    margin-right: 25px;
    font-size: 32px;
}

.project-demo > * {
    filter:drop-shadow(0px 0px 5px #000);
}

.demo {
    margin-left: 25px;
    cursor: pointer;
    width: 100%;
}

.demo:hover{
    color: #000;
    transition: 1s all;
}

@media only screen and (max-width: 400px){
    .pc{
        width: 300px;
    }

    .project-details{
        font-size: 16px;
    }

    .project-details{
        align-items: flex-start;
    }

    .project-demo{
        width: fit-content;
    }
}

@media only screen and (max-width: 1400px){
    .project-main{
        flex-direction: column;
        align-items: center;
        padding: 100px 25px 0 25px;
        overflow: hidden;
    }

    .project-image{
        min-width: 100%;
        margin-bottom: -25px;
        border-top-right-radius: 15px;
    }

    .pc{
        max-width: 400px;
        padding: 0 25px;
        margin-left: 50%;
        transform: translateX(-50%);
    }
    
    .mobile{
        max-width: 250px;
        padding: 0 25px;
        margin-left: 50%;
        transform: translateX(-50%);
    }

    .project-info{
        min-width: fit-content;
        border-top-right-radius: 0px;
        border-bottom-left-radius: 15px;
    }

    .project-details{
        flex-direction: column;
    }

    .project-demo{
        text-align: center;
        margin-top: 15px;
    }
}