.video-player{
    position: fixed;
    z-index: 3;
    width: 100%;
    height: 105%;
    background-color: var(--color-light-grey);
    transform: translateY(-115px);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.video-player > video{
    width: 95%;
    max-width: 900px;
}

.controls{
    font-size: 32px;
    color: #0075FF;
    margin-top: 25px;
}

.controls > * {
    cursor: pointer;
}

.controls > .player-pause{
    display: none;
}

.video-time{
    width: 200px;
    margin-left: 15px;
    transform: translateY(-2px);
}

.player-close{
    color: #0075FF;
    margin-bottom: 25px;
    font-size: 32px;
    cursor: pointer;
}

.stopScroll{
    touch-action: none;
    -ms-touch-action: none;
    overflow: hidden;
}