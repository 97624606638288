*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: Arial, Helvetica, sans-serif;
    -webkit-touch-callout: none; /* iOS Safari */
      -webkit-user-select: none; /* Safari */
       -khtml-user-select: none; /* Konqueror HTML */
         -moz-user-select: none; /* Old versions of Firefox */
          -ms-user-select: none; /* Internet Explorer/Edge */
              user-select: none; /* Non-prefixed version, currently
                                    supported by Chrome, Opera and Firefox */
    --color-white: #fff;
    --color-light-blue: #a6c8e7;
    --color-blue: #0052A3;
    --color-light-grey: #6d6d6d;
    --color-moon: #2B2926;
    --color-sun: #e79b1f;
}

.select {
    -webkit-touch-callout: all; /* iOS Safari */
      -webkit-user-select: all; /* Safari */
       -khtml-user-select: all; /* Konqueror HTML */
         -moz-user-select: all; /* Old versions of Firefox */
          -ms-user-select: all; /* Internet Explorer/Edge */
              user-select: all; /* Non-prefixed version, currently
                                    supported by Chrome, Opera and Firefox */
  }

/* Loading - Start */

.loading{
    min-width: 100vw;
    min-height: 100vh;
    background-color: var(--color-light-blue);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.loading img{
    min-width: 150px;
    max-width: 150px;
    margin-bottom: 50px;
}

/* Loading - End */
/* NavBar - Start */

.navbar{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 65px;
    color: var(--color-white);
    position: fixed;
    width: 100%;
    z-index: 1;
}

.navbar img{
    width: 32px;
    margin-left: 50px;
    cursor: pointer;
}

.navbar ul > *{
    display: inline-flex;
    list-style: none;
    text-transform: uppercase;
    color: var(--color-blue);
    padding: 0 15px;
    text-decoration: none;
    font-weight: 600;
}

.navbar ul li{
    padding: 2px 0;
}

.navbar ul > *:hover, #active{
    color: var(--color-white);
    background-color: var(--color-blue);
    transform: skew(25deg) scale(1.1);
    transition: 0.5s all;
}

.navbar ul > *:hover li, #active li{
    transform: skew(-25deg);
    transition: 0.5s all;
}

.settings{
    display: flex;
    flex-direction: row;
    margin-right: 30px;
}

.settings img{
    margin: 0;
    width: 26px;
    border-radius: 50%;
    border: 2px solid var(--color-blue);
}

.darkmode{
    background: var(--color-blue);
    display: flex;
    height: 26px;
    width: 50px;
    justify-content: space-between;
    border-radius: 50px;
    padding: 5px;
    position: relative;
    margin-right: 20px;
}

.darkmode:hover{
    cursor: pointer;
}

.fa-moon{
    color: var(--color-moon);
}

.fa-sun{
    color: var(--color-sun);
}

.ball{
    top: 2px;
    left: 2px;
    border-radius: 50%;
    width: 22px;
    height: 22px;
    background-color: #eee;
    position: absolute;
    transition: 1s all;
}

.burguer{
    display: none;
}

/* NavBar - End */
/* About - Start */

.about-home{
    padding: 50px 0;
    display: flex;
    flex-direction: row;
    margin: 0 auto;
    max-width: 1400px;
    transform: translateY(85px);
}

.about-home img{
    margin: auto 100px auto 100px;
    width: 250px;
    height: 250px;
    border-radius: 50%;
    border: 7px solid var(--color-white);
}

.h2-background{
    transform: skewX(25deg);
    background-color: var(--color-white);
    width: fit-content;
    padding: 0 15px;
}

.about-home h2,
.services-home h2,
.skills-home h2,
.about h2,
.error h2{
    color: var(--color-blue);
    font-size: 32px;
    text-transform: uppercase;
    position: relative;
    transform: skewX(-25deg);
}

.about-text{
    width: 100%;
    margin-right: 50px;
    margin-bottom: 25px;
}

.about-cards{
    margin-top: 50px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.about-cards div{
    min-width: 250px;
    flex: 0 0 45%;
    margin-bottom: 25px;
    margin-right: 5%;
}

.about-cards p{
    color: var(--color-white);
    font-size: 20px;
}

.about-cards h3{
    text-transform: capitalize;
    color: var(--color-blue);
    width: fit-content;
    padding: 2px;
    margin-top: 2px;
}

.about-info{
    margin-top: 10px;
    color: var(--color-white);
    font-size: 18px;
    text-align: justify;
}

.about-info p{
    margin-top: 10px;
}

/* About - End */
/* Services - Start */

.background-services-home{
    background-image: url(./img/background.jpg);
    background-size: cover;
    background-attachment: fixed;
    background-repeat: no-repeat;
    transform: skew(0deg, 2deg);
    margin-top: 65px;
}

.services-home{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.services-home .h2-background{
    transform: skew(25deg, -2deg);
    margin-top: 50px;
}

.services-cards{
    transform: skewY(-2deg);
    margin-top: 75px;
    display: flex;
    flex-direction: row;
    width: 80%;
    justify-content: space-between;
    margin-bottom: 100px;
    max-width: 1400px;
}

.services-card{
    display: flex;
    flex-direction: row;
    align-content: center;
    margin: 0 10px;
    min-width: 450px;
    max-width: 450px;
    border-radius: 20px;
    color: var(--color-white);
    padding: 25px;
}

.services-fontawesome{
    margin: auto;
    margin-right: 50px;
    margin-left: 25px;
}

.services-card-info h3{
    font-size: 22px;
    font-style: italic;
    margin-bottom: 25px;
}

.services-card-info ul{
    list-style: none;
    margin-bottom: 15px;
}

.services-card-info ul li{
    margin-bottom: 10px;
}

.services-card-info ul li > * {
    margin-right: 10px;
}

/* Services - End */
/* Skills - Start */

.skills-home{
    width: 100%;
    transform: translateY(65px);
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 75px;
}

.skills{
    margin-top: 50px;
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 50px 0;
}

.skills>*{
    flex: 1 1 50%;
}

.card-skill{
    display: flex;
    flex-direction: row;
    align-items: baseline;
    max-width: 50%;
    padding: 0 50px;
    font-size: 18px;
}

.card-skill:nth-child(odd) h3{
    max-width: 115px;
    min-width: 115px;
    text-align: center;
    border-right: 2px solid rgb(218, 216, 216);
    margin-right: 10px;
    padding-bottom: 25px;
}

.card-skill:nth-child(even) h3{
    max-width: 60px;
    min-width: 60px;
    border-right: 2px solid rgb(218, 216, 216);
    margin-right: 10px;
    text-align: center;
    padding-bottom: 25px;
}

.card-skill:last-child h3{
    padding-bottom: 0;
}

.card-skill:nth-child(odd){
    justify-content: flex-end;
}

/* Skills - End */
/* Footer - Start */

footer::before{
    content: '';
    display: flex;
    position: absolute;
    height: 3px;
    min-width: 90vw;
    transform: translateY(-25px);
}

footer{
    width: 100%;
    margin: 0 auto;
    margin-top: 175px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding-bottom: 25px;
}

#fontawesome-footer{
    font-size: 50px;
    text-decoration: none;
    margin: 0 15px;
}

.footer-card:nth-child(1) a:hover{
    color: #0A66C2;
    transition: 0.5s all;
    stroke: var(--color-white);
    stroke-width: 20px;  
    padding: 15px;
}

.footer-card:nth-child(4) a:hover{
    color: #00E676;
    transition: 0.5s all;
    stroke: var(--color-white);
    stroke-width: 20px;
    padding: 15px;
}

.footer-card:nth-child(2) a:hover{
    color: #DD3661;
    transition: 0.5s all;
    stroke: var(--color-white);
    stroke-width: 20px;  
    padding: 15px;
}

.footer-card:nth-child(3) a:hover{
    color: #5EB7DF;
    transition: 0.5s all;
    stroke: var(--color-white);
    stroke-width: 20px;  
    padding: 15px;
}

/* Footer - End */

@media only screen and (max-width: 500px){
    .skills-home .skills{
        background: unset;
    }

    .card-skill > *{
        color: var(--color-white);
    }

    #fontawesome-footer{
        font-size: 32px;
    }
}

@media only screen and (max-width: 768px){

    /* NavBar and Menu - Start */

    .navbar ul{
        flex-direction: column;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        display: none;
    }

    .navbar{
        transition: .1s all;
    }

    .navbar ul > *{
        text-align: center;
        margin: 5px auto;
    }

    .active{
        height: 150px;
        align-items: flex-start;
        padding-top: 15px;
    }

    .settings{
        position: absolute;
        right: -20px;
        top: 55px;
        flex-direction: column;
        display: none;
    }

    .settings .darkmode{
        margin-bottom: 10px;
    }

    .settings img{
        margin-left: 11px;
        min-width: 25px;
        min-height: 25px;
    }

    #open{
        display: flex;
    }

    .burguer{
        display: flex;
        color: var(--color-blue);
        margin-right: 40px;
        cursor: pointer;
        padding: 10px;
    }
    /* NavBar and Menu - End */

    .services-card{
        max-width: 95%;
        min-width: 95%;
        flex-direction: column;
    }

    .services-fontawesome{
        margin-bottom: 25px;
        margin-left: 50%;
        transform: translate(-50%);
    }

    .about-home h2,
    .services-home h2,
    .skills-home h2,
    .about h2,
    .error h2{
        font-size: 26px;
    }
}

@media only screen and (max-width: 1100px){
    .about-home{
        flex-direction: column;
        align-items: center;
    }

    .about-home img{
        margin-top: 50px;
    }

    .about-text{
        width: 100%;
        margin-right: 0px;
        padding: 0 25px;
    }

    .about-text .h2-background{
        text-align: center;
        position: absolute;
        min-width: 200px;
        top: 25px;
        left: 50%;
        transform: skewX(25deg) translateX(-50%);
    }

    .skills{
        display: flex;
        flex-direction: column;
    }

    .card-skill{
        margin-bottom: 25px;
        margin: 0 auto;
    }

    .skills .card-skill h3{
        border-right: none;
        font-size: 18px;
        text-align: left;
        max-width: 100px;
        min-width: 100px;
        margin-left: -25px;
    }
    
    .skills .card-skill > * {
        font-size: 14px;
    }

    .card-skill:nth-child(odd){
        justify-content: flex-start;
    }
}

@media only screen and (max-width: 1220px){
    .services-cards{
        flex-direction: column;
        align-items: center;
    }

    .services-card:first-child{
        margin-bottom: 75px;
    }
}